import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { Buttons, Image, Overlay, Scroller, Slider } from '@cian/slider';
import cx from 'clsx';
import * as React from 'react';

import { TourSlideContainer } from 'shared/containers/TourSlideContainer';
import { IHotkey, useHotkeys } from 'shared/hooks/useHotkeys';

import * as styles from './GalleryInner.css';
import { ButtonFullscreen } from './components/ButtonFullscreen';
import { MAP_TYPE_TO_PLAYERS } from './constants';
import { EMediaType, TMedia } from '../../types';
import { CianLayout } from '../CianLayout';
import { PaginationThumbs } from '../PaginationThumbs';
import { VideoPlayable } from '../VideoPlayable';

interface IGalleryInnerSlots {
  slotScrollerEnd?: React.ReactNode;
}

interface IGalleryInnerProps extends IGalleryInnerSlots {
  autofocus?: boolean;
  media: TMedia[];
  bordered?: boolean;
  fullscreenable?: boolean;
  keyboard?: boolean;
  objectFit?: React.ComponentProps<typeof Image>['objectFit'];
  thumbsBounded?: boolean;
  videoPlayable?: boolean;
  onSlideChange?(index: number): void;
  onScrollerClick?(): void;
  withBackgroundBlur?: boolean;
  currentSlide?: number;
}

const GalleryInnerComponent: React.ForwardRefRenderFunction<
  React.ElementRef<typeof Slider> | null,
  IGalleryInnerProps
> = (
  {
    autofocus,
    media,
    bordered,
    fullscreenable,
    keyboard = true,
    objectFit,
    thumbsBounded,
    videoPlayable,
    slotScrollerEnd,
    withBackgroundBlur,
    onSlideChange,
    onScrollerClick,
    currentSlide,
  },
  ref,
) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sliderRef = React.useRef<React.ElementRef<typeof Slider>>(null);
  const paginationThumbsRef = React.useRef<React.ElementRef<typeof PaginationThumbs>>(null);

  const handleScrollEnd = React.useCallback(() => {
    paginationThumbsRef.current?.scrollCurrentThumbIntoView();
  }, []);

  const handleScrollerClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target?.closest('a, button')) {
        return;
      }

      if (onScrollerClick) {
        onScrollerClick();
      }
    },
    [onScrollerClick],
  );

  const hotKeys = React.useMemo<IHotkey[]>(
    () => [
      {
        onKeyDown: () => sliderRef.current?.scrollPrev(),
        keyCode: 'ArrowLeft',
        disabled: !keyboard,
      },
      {
        onKeyDown: () => sliderRef.current?.scrollNext(),
        keyCode: 'ArrowRight',
        disabled: !keyboard,
      },
    ],
    [keyboard],
  );

  const { onKeyDown } = useHotkeys(hotKeys);

  React.useImperativeHandle<React.ElementRef<typeof Slider> | null, React.ElementRef<typeof Slider> | null>(
    ref,
    () => sliderRef.current,
    [],
  );

  useIsomorphicLayoutEffect(() => {
    if (autofocus) {
      requestAnimationFrame(() => {
        containerRef.current?.focus();
      });
    }
  });

  return (
    <div ref={containerRef} className={styles['container']} tabIndex={0} onKeyDown={onKeyDown}>
      <Slider ref={sliderRef} loop onSlideChange={onSlideChange} behavior="auto">
        <div
          className={cx(styles['scroller'], { [styles['scroller--bordered']]: bordered })}
          onClick={handleScrollerClick}
          data-name="Scroller"
        >
          <Scroller onScrollEnd={handleScrollEnd}>
            {media.map((m, index) => {
              switch (m.type) {
                case EMediaType.Layout:
                case EMediaType.Photo: {
                  return (
                    <Image
                      alt={m.description}
                      key={`slide_${index}`}
                      src={m.src}
                      objectFit={m.type === EMediaType.Layout ? 'contain' : objectFit || 'cover'}
                      loading={index === 0 ? null : undefined}
                      decoding={index === 0 ? null : undefined}
                      blurBackground={withBackgroundBlur && m.type !== EMediaType.Layout}
                      priority={index === 0}
                    />
                  );
                }
                case EMediaType.Video:
                case EMediaType.VideoKinescope:
                case EMediaType.VideoVk: {
                  const Player = MAP_TYPE_TO_PLAYERS[m.type];

                  return (
                    <VideoPlayable
                      key={`slide_${index}`}
                      playable={videoPlayable}
                      src={m.src}
                      previewUrl={m.previewUrl}
                      player={<Player {...m} />}
                      withBackgroundBlur={withBackgroundBlur}
                      backgroundSize="contain"
                      isActive={currentSlide === index}
                      videoSource={m.type}
                    />
                  );
                }
                case EMediaType.Tour:
                  return <TourSlideContainer />;
                case EMediaType.CianLayout:
                  return <CianLayout key={`slide_${index}`} src={m.src} deoptimised={index === 0} />;
                case EMediaType.Component:
                  return m.component;
              }
            })}
          </Scroller>
          {(fullscreenable || media.length > 1) && (
            <Overlay>
              {fullscreenable && <ButtonFullscreen />}
              {media.length > 1 && <Buttons size="M" />}
            </Overlay>
          )}
          {slotScrollerEnd}
        </div>
        <div className={cx(styles['thumbs-wrapper'], { [styles['thumbs-wrapper--bounded']]: thumbsBounded })}>
          <PaginationThumbs ref={paginationThumbsRef} media={media} />
        </div>
      </Slider>
    </div>
  );
};

export const GalleryInner = React.memo(React.forwardRef(GalleryInnerComponent));
